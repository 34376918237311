import { css } from '@emotion/react'
import { Avatar, Button, ButtonLink, Text, TextContainer } from '@orus.eu/pharaoh'
import { memo, type ReactElement, type ReactNode } from 'react'
import { assert } from '../../lib/errors'
import { useDisconnect } from '../../lib/hooks/use-disconnect'
import { useSession } from '../../lib/session'
import { usePermissions } from '../../lib/use-permissions'
import { ActionPageLayout } from './action-page-layout'
import { Page } from './page'

/**
 * Wrapper that prevents a page created for customer to be accessed by orus agents or by partners. This is useful
 * to simplify the customer pages by letting them assume that the user has a contract, instead of implementing
 * empty states everywhere, and leading backoffice users to think that there is a bug.
 */
export const NoOrusOrPartnerAgents = memo<{ children: ReactNode }>(function NoOrusOrPartnerAgents({ children }) {
  const {
    user,
    permissions: { type },
  } = useSession()

  if (user && ['platform', 'partner'].includes(type)) {
    assert('email' in user, 'email not found in orus agent user')
    return <AgentBlocker email={user.email} />
  }

  return children
})

export const AgentBlocker = memo<{ email: string }>(function AgentBlocker({ email }): ReactElement {
  const disconnect = useDisconnect()
  const isPlatform = usePermissions().type === 'platform'

  return (
    <Page>
      <ActionPageLayout
        body={
          <>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: mediumspace;
              `}
            >
              <Avatar icon="users-solid" size="80" />
              <TextContainer variant="body1">
                <Text>
                  Tu es sur l&apos;<strong>app client</strong>, mais connecté(e) avec un compte{' '}
                  {isPlatform ? `d'agent` : `partenaire`} Orus <strong>{email}</strong>
                </Text>
                {isPlatform ? (
                  <Text>
                    Déconnecte toi, puis connecte toi avec un compte client pour voir des choses intéressantes ici, ou
                    va dans le backoffice.
                  </Text>
                ) : undefined}
              </TextContainer>
            </div>
          </>
        }
        actions={
          <>
            {isPlatform ? (
              <Button variant="secondary" onClick={disconnect}>
                Me déconnecter
              </Button>
            ) : undefined}
            <ButtonLink variant="primary" to={isPlatform ? '/bak/home' : '/'}>
              Aller sur le backoffice
            </ButtonLink>
          </>
        }
        maxWidth="sm"
      />
    </Page>
  )
})
